import _ from 'lodash';
import { currencySymbols } from '@/data/currencySymbols';
import { gtmAddToCart } from '@/utils/gtmEvents';

export const ACTION_REPLACE_PLANS = 'plan/replacePlans';
export const ACTION_FINISH_FETCHING_PLANS = 'plan/finishFetchingPlans';
export const ACTION_SET_BILLING_CYCLE = 'plan/setBillingCycle';
export const GETTER_SORTED_CATEGORIES = 'plan/sortedCategories';
export const GETTER_SELECTED_PLAN = 'plan/selectedPlan';
export const MUTATION_SET_PLAN_FINAL_DATA = 'plan/setPlanFinalData';
export const MUTATION_SET_COUPON = 'plan/setCoupon';
export const MUTATION_SET_COUPON_PLAN_PATH = 'plan/setCouponPlanPath';
export const MUTATION_SET_HIDDEN_PLAN = 'plan/setHiddenPlan';
export const MUTATION_SET_PLAN = 'plan/setPlan';
export const MUTATION_SET_IS_PLUS_PACKAGE_SELECTED = 'plan/setIsPlusPackageSelected';

export const plan = {
  namespaced: true,
  state: {
    activeSubscription: null,
    currentPlan: null,
    currentBillingCycle: null,
    currentPlanOptions: [],
    currency: 'GBP',
    coupon: {
      value: '',
      discount: null,
      validForNumberOfPeriods: null,
      singlePlanPath: null,
    },
    apiPlans: {
      data: null,
      error: null,
    },
    hiddenPlan: null,
    planOptions: {
      speed: null,
      devices: null,
    },
    cost: {
      isZeroCost: null,
      totalCost: null,
      totalCostExcludingTax: null,
    },
    planFinalData: {
      speed: null,
      devices: null,
      totalCost: null,
    },
  },
  getters: {
    showPlansCycle(state) {
      if (!state.apiPlans.data.plans) {
        return false;
      }
      const uniquePeriods = new Set();
      for (const item of state.apiPlans.data.plans) {
        uniquePeriods.add(JSON.stringify(item.period));
      }
      // show PlansCycle if there are more than 1 unique period
      return uniquePeriods.size > 1;
    },
    currentSubscription(state) {
      return state.apiPlans.data && state.apiPlans.data.plans && state.activeSubscription
        ? _.find(state.apiPlans.data.plans, ['priceId', state.activeSubscription.planId])
        : null;
    },
    selectedPlan(
      state,
      rootState,
      rootGetters,
      store,
    ) {
      if (state.hiddenPlan && state.hiddenPlan.priceId === state.currentPlan) {
        const foundedPlan = state.hiddenPlan;
        gtmAddToCart(foundedPlan);
        return foundedPlan;
      }
      const isSFA = store['siteInfo/isSFA'];
      const isWifinityOneEnabled = store['siteInfo/isWifinityOneEnabled'];
      // if WifinityOne PLAN
      if (isWifinityOneEnabled || isSFA) {
        const packageDurations = ['daily', 'monthly'];
        let foundedPlan;

        for (const duration of packageDurations) {
          for (const packagePlan of state.apiPlans.data.plans) {
            if (foundedPlan) break;
            foundedPlan = _.find(packagePlan[duration], ['priceId', state.currentPlan]);
          }
        }

        if (!foundedPlan) return;

        foundedPlan.groupTitle = foundedPlan.groupName;
        if (foundedPlan.groupName === '1 Month' && foundedPlan.recurring) {
          foundedPlan.groupTitle = 'Monthly';
        }

        gtmAddToCart(foundedPlan);
        return foundedPlan;
      }

      if (state.apiPlans.data && state.apiPlans.data.plans && state.currentPlan) {
        const foundedPlan = _.find(state.apiPlans.data.plans, ['priceId', state.currentPlan]);
        gtmAddToCart(foundedPlan);
        return foundedPlan;
      }

      return null;
    },
    planPackagePlus(state) {
      return state.apiPlans.data.plans.filter(plan => plan.packageType === 'Plus')[0];
    },
    currentCategory() {
      return {
        id: '4weeks',
        title: '4 Weeks',
      };
    },
    currencySymbol(state) {
      return state.currency
        ? currencySymbols[state.currency]
        : null;
    },
    sortedCategories(state) {
      if (!state.apiPlans.data) {
        return [];
      }

      //  Map each plan to object that only contains necessary info
      let categories = _.map(state.apiPlans.data.plans, (plan) => {
        plan.groupTitle = plan.groupName;
        if (plan.groupName === '1 Month' && plan.recurring) {
          plan.groupTitle = 'Monthly';
        }
        return {
          id: plan.category,
          title: plan.groupTitle,
        };
      });

      //  Remove any duplicates
      categories = _.uniqBy(categories, cat => cat.id);

      //  Sort by length of time
      categories = _.sortBy(categories, [
        function (cat) {
          let period = cat.id.replace(/[0-9]/g, ''); //  Extract letters from id
          if (period.slice(-1) === 's') period = period.slice(0, -1); //  Remove 's' from period string to make it singular
          return _.indexOf(['day', 'week', 'month', 'year'], period);
        }, function (cat) {
          return Number(cat.id.replace(/[A-Za-z]/g, '')); //  Extract numbers from id
        },
      ]);

      //  Move 4weeks tab to the start if it's there
      const moveToStart = _.filter(categories, cat => cat.id === '4weeks');
      if (moveToStart.length === 1) {
        categories = _.filter(categories, cat => cat.id !== '4weeks');
        categories.unshift(moveToStart[0]);
      }

      // Move 1month tab to the start if it's there
      const monthlyFirst = _.filter(categories, cat => cat.id === '1month');
      if (monthlyFirst.length === 1) {
        categories = _.filter(categories, cat => cat.id !== '1month');
        categories.unshift(monthlyFirst[0]);
      }

      return categories;
    },
    selectedPlanPeriod(state, getters) {
      const selectedPlan = getters.selectedPlan;

      if (!selectedPlan) {
        return null;
      }

      const { amount, magnitude } = selectedPlan.period;

      if (amount > 1) {
        // "28 days"
        return `${amount}\xa0${magnitude.toLowerCase()}`;
      }
      // months -> month
      return `${amount}\xa0${magnitude.slice(0, -1).toLowerCase()}`;
    },
    couponPeriodCount({ coupon }) {
      const { validForNumberOfPeriods } = coupon;

      if (validForNumberOfPeriods === null) {
        return null;
      }

      return `${validForNumberOfPeriods}\xa0${validForNumberOfPeriods === 1 ? 'period' : 'periods'}`;
    },
  },
  mutations: {
    setCost(state, { totalCost, isZeroCost, totalCostExcludingTax }) {
      state.cost.totalCost = totalCost;
      state.cost.isZeroCost = isZeroCost;
      state.cost.totalCostExcludingTax = totalCostExcludingTax;
    },
    setCoupon(state, { couponField, validForNumberOfPeriods }) {
      state.coupon.value = couponField;
      state.coupon.validForNumberOfPeriods = validForNumberOfPeriods;
    },
    setCouponPlanPath(state, { planPath }) {
      state.coupon.singlePlanPath = planPath;
    },
    setDiscount(state, { discount }) {
      state.coupon.discount = discount;
    },
    removeCoupon(state) {
      state.coupon.value = '';
      state.coupon.discount = '';
      state.coupon.validForNumberOfPeriods = '';
    },
    setPlan(state, plan) {
      state.currentPlan = plan;
    },
    setHiddenPlan(state, plan) {
      state.hiddenPlan = plan;
    },
    setBillingCycle(state, billingCycle) {
      state.currentBillingCycle = billingCycle;
    },
    // setPlanOptions (state, planOptions) {
    //   state.currentPlanOptions = planOptions
    // },
    setPlanOptions(state, planOptions) {
      state.planOptions.speed = planOptions.speed;
      state.planOptions.devices = planOptions.devices;
    },
    setPlanFinalData(state, finalData) {
      state.planFinalData.totalCost = finalData.plan.price;
      state.planFinalData.speed = finalData.plan.baseSpeedMbps;
      state.planFinalData.devices = finalData.totalDevices;
    },
    finishFetchingPlans(state, data, error) {
      state.apiPlans.data = { plans: data };
      state.apiPlans.error = error;
    },
    setPlansError(state, error) {
      state.apiPlans.error = error;
    },
    setActiveSubscription(state, data) {
      state.activeSubscription = data;
    },
  },
  actions: {
    replacePlans({
      commit, rootGetters, dispatch,
    }) {
      const isSFA = rootGetters['siteInfo/isSFA'];
      const isWifinityOneEnabled = rootGetters['siteInfo/isWifinityOneEnabled'];
      let plans;
      if (isWifinityOneEnabled || isSFA) {
        plans = dispatch('getCustomPlans');
      } else {
        plans = dispatch('getPlans');
      }
      return plans
        .then(async (response) => {
          await commit('finishFetchingPlans', response.data);
          if (!isWifinityOneEnabled && !isSFA) {
            await commit('setBillingCycle', rootGetters['plan/sortedCategories'].length ? rootGetters['plan/sortedCategories'][0].id : null);
          }
          await commit('finishFetchingPlans', response.data);
        }, async (err) => {
          await commit('finishFetchingPlans', null, err);
          throw err;
        });
    },
    getPlans({ rootState }) {
      const params = new URLSearchParams();

      const urlParams = params.toString() ? `?${params.toString()}` : '';
      const uri = `/plans/site/${rootState.siteInfo.locationID}${urlParams}`;

      return rootState.api.stardust.get(uri);
    },
    getCustomPlans({ rootState }) {
      const params = new URLSearchParams();

      const urlParams = params.toString() ? `?${params.toString()}` : '';
      const uri = `/plans/site/custom/${rootState.siteInfo.locationID}${urlParams}`;

      return rootState.api.stardust.get(uri);
    },
    fetchActiveSubscription({ commit, rootState }) {
      return rootState.api.stardust.get('/subscription')
        .then((response) => {
          commit('setActiveSubscription', response.data.activeSubscription);

          return response.data;
        });
    },
    activateFreeSubscription({ rootState }, payload) {
      return rootState.api.stardust.post('/internal-subscription/activate-free', payload);
    },
  },
};

// function generateInvoicesURI (id, offset, records) {
//   var uri = {
//     id: id,
//     offset: offset ? 'offset=' + offset : '',
//     records: records ? 'records=' + records : ''
//   }
//   var qMark = uri.offset !== '' || uri.records !== '' ? '?' : ''
//   var amp = uri.offset !== '' && uri.records !== '' ? '&' : ''
//   return '/billing/subscriptions/' + uri.id + '/invoices' + qMark + uri.offset + amp + uri.records
// }
